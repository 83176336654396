<template>
  <LoadingState v-if="isLoading" />
  <div v-else class="cbtDetail">
    <template v-if="newComponent">
      <EditSoalCbt
        :listSoal="newDataSoal"
        :isAttendanceSubmitted="isAttendanceSubmitted"
        :useCard="true"
        :isCbt="true"
        @save-edit="saveEdit"
        @cancel-edit="cancelEdit"
      />
    </template>
    <template v-else-if="isPreviewStudent">
      <!-- <PreviewSoalStudent /> -->
      <Card>
        <ExamCBT
          :isTeacher="isTeacher"
          :questions="dataSoal"
          @back-detail="backDetail"
        />
      </Card>
    </template>
    <template v-else>
      <a-row :gutter="32">
        <a-back-top :visibilityHeight="400" />
        <a-col :sm="24" :lg="14">
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0">
                <strong
                  >QUESTIONS {{ editable ? "EDITING" : "DETAILS" }}</strong
                >
              </div>
              <div class="text-muted mb-3">
                You can {{ editable ? "edit" : "see" }} Questions here
              </div>
              <div class="d-lg-flex flex-wrap">
                <a-form-item class="mr-lg-3">
                  <a-tooltip
                    v-if="statusSlot"
                    placement="top"
                    title="This may take up to 1 minute, depending on your internet connection."
                  >
                    <a-button
                      @click.prevent="cekEmpty"
                      :loading="loadingEditQuestion"
                      :type="editable === true ? 'danger' : 'default'"
                      class="mr-2 btn btn-outline-warning rounded"
                      style="width: 100%; height: 40px"
                    >
                      <a-icon
                        v-if="!loadingEditQuestion"
                        :type="editable ? 'close' : 'edit'"
                      />
                      {{
                        editable
                          ? "Cancel"
                          : loadingEditQuestion
                          ? "Please Wait..."
                          : "Edit"
                      }}
                    </a-button>
                  </a-tooltip>
                </a-form-item>
                <!-- <a-button
                :loading="loadingUpdateQuestion"
                @click.prevent="updateQuestion"
                v-show="dataSoal.length && editable"
                type="primary"
              >
                <a-icon v-if="!loadingUpdateQuestion" type="save" />
                {{loadingUpdateQuestion ? 'Updating...' : 'Update & Preview'}}
                </a-button>-->
                <a-form-item class="mr-lg-3">
                  <a-button
                    v-if="!editable"
                    @click.prevent="duplicateCbt"
                    type="default"
                    style="width: 100%; height: 40px"
                    class="btn btn-outline-dark rounded"
                  >
                    <a-icon v-if="!loadingExport" type="copy" />Duplicate
                  </a-button>
                </a-form-item>
                <a-form-item class="mr-lg-3">
                  <a-button
                    v-if="!editable"
                    @click.prevent="previewStudent"
                    type="default"
                    class="btn btn-outline-primary rounded"
                    style="width: 100%; height: 40px"
                  >
                    <a-icon v-if="!loadingExport" type="profile" />Preview as
                    Student
                  </a-button>
                </a-form-item>
                <a-form-item class="ml-lg-auto">
                  <a-button
                    :loading="loadingExport"
                    v-if="!editable"
                    @click.prevent="generateReport"
                    type="default"
                    class="btn btn-outline-danger rounded"
                    style="width: 100%; height: 40px"
                  >
                    <a-icon v-if="!loadingExport" type="file-pdf" />
                    {{ ExportText }}
                  </a-button>
                </a-form-item>
              </div>
              <hr />
              <div v-if="dataSoal.length">
                <CounterCbt :counterCBT="counterCBT" />
                <template v-if="editable">
                  <div v-for="(item, i) in newDataSoal" :key="i">
                    <a-row :gutter="16">
                      <a-col :span="1">
                        <h3>{{ numberQuestion(i) }}.</h3>
                      </a-col>
                      <a-col :span="23">
                        <div class="d-flex flex-row">
                          <a-form-item class="mr-3">
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Question Type</span>
                              </template>
                              <a-input
                                read-only
                                size="large"
                                :value="item.tipe_soal"
                                style="width: 170px"
                              />
                            </a-tooltip>
                          </a-form-item>
                          <a-form-item class="mr-3">
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Bobot</span>
                              </template>
                              <a-input-number
                                size="large"
                                :min="1"
                                :max="100"
                                :default-value="item.bobot_soal"
                                @change="handleBobot($event, i)"
                                :disabled="isAttendanceSubmitted"
                                style="width: 80px"
                              />
                            </a-tooltip>
                          </a-form-item>
                          <a-form-item class="mr-3">
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Criteria</span>
                              </template>
                              <a-select
                                size="large"
                                :default-value="item.kriteria"
                                style="width: 100px"
                                @change="handleCriteria($event, i)"
                              >
                                <a-select-option value="MD">MD</a-select-option>
                                <a-select-option value="SD">SD</a-select-option>
                                <a-select-option value="SK">SK</a-select-option>
                              </a-select>
                            </a-tooltip>
                          </a-form-item>
                          <a-form-item
                            v-if="item.tipe_soal === 'Multiple Choice'"
                            class="mr-3"
                          >
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Correct Answer</span>
                              </template>
                              <a-select
                                size="large"
                                :default-value="item.kunci_jawaban"
                                style="width: 60px"
                                @change="handleCorrectAnswer($event, i)"
                                :disabled="isAttendanceSubmitted"
                              >
                                <a-select-option value="a">a</a-select-option>
                                <a-select-option value="b">b</a-select-option>
                                <a-select-option value="c">c</a-select-option>
                                <a-select-option value="d">d</a-select-option>
                              </a-select>
                            </a-tooltip>
                          </a-form-item>
                          <a-button
                            @click.prevent="removeQuestion(i, item)"
                            class="ml-auto"
                            :loading="loadingEditQuestion"
                            size="large"
                            type="danger"
                          >
                            <a-icon type="file-excel" />Delete Question
                          </a-button>
                        </div>
                        <div class="d-flex flex-row mb-4">
                          <a-tooltip placement="top">
                            <template slot="title">
                              <span>Standar Kompetensi</span>
                            </template>
                            <a-textarea
                              class="mr-3"
                              v-model="item.standar_kompetensi"
                              placeholder="Please fill this column"
                              :auto-size="{ minRows: 3, maxRows: 5 }"
                            />
                          </a-tooltip>
                          <a-tooltip placement="top">
                            <template slot="title">
                              <span>Indikator</span>
                            </template>
                            <a-textarea
                              class="mr-3"
                              v-model="item.indikator"
                              placeholder="Please fill this column"
                              :auto-size="{ minRows: 3, maxRows: 5 }"
                            />
                          </a-tooltip>
                        </div>
                        <ckeditor
                          v-model="item.soal"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>
                        <template v-if="item.tipe_soal === 'Multiple Choice'">
                          <div
                            class="answer py-2"
                            v-for="(itemJawaban, j) in item.jawaban"
                            :key="j"
                          >
                            <ckeditor
                              v-model="item.jawaban[j]"
                              :editor="editor"
                              :config="editorConfig"
                            ></ckeditor>
                          </div>
                        </template>
                      </a-col>
                    </a-row>
                    <hr />
                  </div>
                  <a-popover
                    v-model="visiblePop"
                    title="Choose Question Type"
                    trigger="click"
                    placement="right"
                  >
                    <a-button
                      @click.prevent="addQuestion('Multiple Choice')"
                      class="mb-3 mt-3"
                      size="large"
                      slot="content"
                      type="default"
                      block
                    >
                      <a-icon type="unordered-list" />Multiple Choice
                    </a-button>
                    <a-button
                      @click.prevent="addQuestion('Essay')"
                      class="mb-3"
                      size="large"
                      slot="content"
                      type="default"
                      block
                    >
                      <a-icon type="align-left" />Essay
                    </a-button>
                    <a-button
                      :loading="loadingEditQuestion"
                      size="large"
                      class="ml-auto"
                      type="primary"
                      block
                    >
                      <a-icon type="file-add" />Add Question ?
                    </a-button>
                  </a-popover>
                </template>
                <template v-else>
                  <ListSoalCbt :listSoal="dataSoal" v-if="!isRenderMathJax" />
                </template>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :sm="24" :lg="10">
          <div
            v-if="role.includes('kurikulum') && detailCbt.status !== 'publish'"
            class="card-full border border-primary"
          >
            <div class="detail">
              <div class="cui__utils__heading mb-0 d-flex flex-row">
                <strong>CBT STATUS</strong>
              </div>
              <div class="text-muted mb-3">
                You can change status of CBT here
              </div>
              <hr />
              <a-button
                :loading="loadingApprove"
                type="primary"
                size="large"
                block
                @click.prevent="approveCBT"
                class="mt-4"
                >Approve CBT</a-button
              >
            </div>
          </div>
          <div class="card-full">
            <div class="detail" v-if="detailCbt.id">
              <div class="cui__utils__heading mb-0">
                <strong>CBT DETAILS</strong>
              </div>
              <div class="text-muted mb-3">You can see CBT here</div>
              <hr />
              <a-row :gutter="16">
                <a-col :span="24" v-if="detailCbt.mata_pelajaran">
                  <a-form-item label="Subject">
                    <a-input
                      size="large"
                      style="width: 100%; height: 40px"
                      :value="detailCbt.mata_pelajaran.nama"
                      :disabled="true"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Type">
                    <a-input
                      size="large"
                      style="width: 100%; height: 40px"
                      v-model="detailCbt.tipe"
                      :disabled="true"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Duration (minutes)">
                    <a-input-number
                      :min="15"
                      :max="180"
                      step="15"
                      size="large"
                      :value="detailCbt.durasi"
                      @change="handleDuration"
                      style="width: 100%"
                    />
                  </a-form-item>
                </a-col>
                <a-col v-if="detailCbt.tipe === 'PH'" :span="24">
                  <a-form-item label="Basic Competence" required :colon="false">
                    <a-select
                      size="large"
                      style="width: 100%; height: 40px"
                      v-model="detailCbt.id_kd"
                    >
                      <a-select-option
                        v-for="item in kdLists"
                        :key="item.id"
                        :value="item.id"
                        >Level {{ item.level ? item.level.nama : "-" }} | (3.{{
                          item.no || ""
                        }}) {{ item.nama_pengetahuan || "" }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <template v-if="detailCbt.tipe">
                  <div
                    v-if="
                      !['PTS', 'PAS'].includes(detailCbt.tipe)
                    "
                  >
                    <a-col :span="24">
                      <a-form-item
                        label="Class to Assign"
                        required
                        :colon="false"
                      >
                        <a-select
                          size="large"
                          class="w-100"
                          mode="multiple"
                          :value="detailCbt.kelasTerpilih"
                          disabled
                        >
                          <a-select-option
                            v-for="kelas in classes"
                            :key="kelas.id"
                            :value="kelas.id"
                          >
                            {{ kelas.level ? kelas.level.nama : "" }}-{{
                              kelas.simbol
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </div>
                  <div v-else>
                    <a-col :span="24">
                      <a-form-item
                        label="Level to Assign"
                        required
                        :colon="false"
                      >
                        <a-select
                          size="large"
                          style="width: 100%; height: 40px"
                          v-model="detailCbt.level.nama"
                          disabled
                        >
                          <!-- <template v-for="level in levels">
                            <a-select-option
                              v-if="levels.length"
                              :key="level.id"
                              :value="level.id"
                              >{{ level.nama }}</a-select-option
                            >
                          </template> -->
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </div>
                  <template v-if="detailCbt.list_pengawas.length">
                    <a-col
                      v-for="item in detailCbt.list_pengawas"
                      :key="item.id_kelas"
                      :span="24"
                    >
                      <a-form-item
                        :label="`${item.kelas} Supervisor`"
                        required
                        :colon="false"
                      >
                        <a-select
                          show-search
                          option-filter-prop="children"
                          style="width: 100%; height: 40px"
                          :filter-option="filterOption"
                          :value="item.id_guru"
                          @change="
                            handleSelectPengawas($event, item.id, 'id_guru')
                          "
                          size="large"
                        >
                          <a-select-option
                            v-for="item in pengawas"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.nama }}</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                      <template
                        v-if="
                          !['PTS', 'PAS'].includes(detailCbt.tipe)
                        "
                      >
                        <a-form-item
                          :label="`${item.kelas} Start At`"
                          required
                          :colon="false"
                        >
                          <a-date-picker
                            :value="item.waktu_ujian_dibuka"
                            show-time
                            format="YYYY-MM-DD HH:mm:ss"
                            @change="
                              (dateMoment, dateString) =>
                                handleSelectPengawas(
                                  dateString,
                                  item.id,
                                  'waktu_ujian_dibuka'
                                )
                            "
                            style="width: 100%"
                            size="large"
                            placeholder=""
                          />
                        </a-form-item>
                        <a-form-item
                          :label="`${item.kelas} End At`"
                          required
                          :colon="false"
                        >
                          <a-date-picker
                            :value="item.waktu_ujian_ditutup"
                            show-time
                            format="YYYY-MM-DD HH:mm:ss"
                            @change="
                              (dateMoment, dateString) =>
                                handleSelectPengawas(
                                  dateString,
                                  item.id,
                                  'waktu_ujian_ditutup'
                                )
                            "
                            style="width: 100%"
                            size="large"
                            placeholder=""
                          />
                        </a-form-item>
                      </template>
                    </a-col>
                  </template>
                </template>
                <!-- <a-col v-if="detailCbt.tipe !== 'PH'" :span="24">
                <a-form-item label="Pengawas CBT">
                  <a-select
                    show-search
                    option-filter-prop="children"
                    style="width:100%;height: 40px;"
                    :filter-option="filterOption"
                    :default-value="detailCbt.id_guru"
                    @change="handleSelectPengawas"
                    size="large"
                  >
                    <a-select-option
                      v-for="item in pengawas"
                      :key="item.id"
                      :value="item.id"
                    >{{item.nama}}</a-select-option>
                  </a-select>
                </a-form-item>
                </a-col>-->
                <!-- <a-col :span="12" v-if="detailCbt.tipe !== 'PH'">
                  <a-form-item label="Pengawas 1">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%; height: 40px"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 1)"
                      size="large"
                      :default-value="selectedPengawas['pengawas1']"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="detailCbt.tipe !== 'PH'">
                  <a-form-item label="Pengawas 2">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%; height: 40px"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 2)"
                      size="large"
                      :default-value="selectedPengawas['pengawas2']"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="detailCbt.tipe !== 'PH'">
                  <a-form-item label="Pengawas 3">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%; height: 40px"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 3)"
                      size="large"
                      :default-value="selectedPengawas['pengawas3']"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="detailCbt.tipe !== 'PH'">
                  <a-form-item label="Pengawas 4">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%; height: 40px"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 4)"
                      size="large"
                      :default-value="selectedPengawas['pengawas4']"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col> -->
                <!-- <a-col :span="12">
                  <a-form-item label="Level">
                    <a-select
                      size="large"
                      style="width: 100%; height: 40px"
                      :default-value="detailCbt.tingkat"
                      @change="handleSelectClass"
                      :disabled="true"
                    >
                      <a-select-option
                        v-for="item in levels"
                        :key="item.id"
                        :value="item.kela.tingkat"
                        >{{ item.kela.tingkat }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col> -->
                <template
                  v-if="['PTS', 'PAS'].includes(detailCbt.tipe)"
                >
                  <a-col :span="24">
                    <a-form-item label="Start at" required :colon="false">
                      <a-date-picker
                        v-model="detailCbt.waktu_ujian_dibuka"
                        :disabled-date="disabledStartDate"
                        show-time
                        format="YYYY-MM-DD HH:mm:ss"
                        @openChange="handleStartOpenChange"
                        style="width: 100%"
                        size="large"
                        placeholder=""
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="24">
                    <a-form-item label="End at" required :colon="false">
                      <a-date-picker
                        v-model="detailCbt.waktu_ujian_ditutup"
                        :disabled-date="disabledEndDate"
                        show-time
                        format="YYYY-MM-DD HH:mm:ss"
                        :open="endOpen"
                        @openChange="handleEndOpenChange"
                        style="width: 100%"
                        size="large"
                        placeholder=""
                      />
                    </a-form-item>
                  </a-col>
                </template>
                <a-col :span="24">
                  <a-form-item label="Intructions">
                    <ckeditor
                      v-model="detailCbt.instruksi"
                      :editor="editor"
                      :config="editorConfigInstruksi"
                    ></ckeditor>
                  </a-form-item>
                </a-col>
                <a-button
                  :loading="loadingAdd"
                  type="primary"
                  size="large"
                  block
                  @click.prevent="generateCBT"
                  :disabled="isFillAll"
                  >Save Changes</a-button
                >
              </a-row>
            </div>
          </div>
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0 d-flex flex-row">
                <strong>REMOVE CBT</strong>
              </div>
              <div class="text-muted mb-3">You can remove CBT here</div>
              <hr />
              <a-button
                :loading="loadingDelete"
                type="danger"
                size="large"
                block
                @click.prevent="deleteCBT"
                class="mt-4"
                >Remove CBT</a-button
              >
            </div>
          </div>
        </a-col>
      </a-row>
    </template>
    <!-- <vue-html2pdf
      :html-to-pdf-options="pdfOptions"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="930"
      filename="hahaha"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
      </section>
    </vue-html2pdf> -->
    <!-- <vue-html2pdf
      :html-to-pdf-options="pdfOptions2"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="1000"
      :filename="fileNameKartuSoal"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf2"
      @beforeDownload="beforeDownload($event)"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
      </section>
    </vue-html2pdf> -->
    <div v-if="loadingExport" class="d-none">
      <template>
        <div id="kartuSoalPdfPrint" style="font-family: `amiri` !important" />
        <template v-for="(item, index) in dataSoal">
          <KartuSoalPdf
            v-if="!isRenderMathJax"
            :format="{ width: '820px', height: '1133px' }"
            :detailCbt="detailCbt"
            :item="item"
            :no="index + 1"
            :key="index"
            :kelas="kelas"

          />
        </template>
      </template>
    </div>
    <div class="d-none">
      <div style="width: 820px; height: 1133px">
        <div id="cobaSoalPdf" style="font-family: 'amiri' !important; width: 820px; height: 1133px"></div>
        <SoalPdf
          v-if="!isRenderMathJax"
          :format="{ width: '820px', height: '1133px' }"
          :detailCbt="detailCbt"
          :kelas="kelas"
          :tanggalUjian="tanggalUjian"
          :dataSoal="dataSoal"

        />
      </div>
    </div>
    <!-- <div class="d-none w-100">
      <div id="reportCbt">
        <template v-for="(item, index) in dataSoal">
          <KartuSoalPdf :detailCbt="detailCbt" :item="item" :no="index" :key="index" />
        </template>
      </div>
    </div> -->
  </div>
</template>

<script>
import moment from 'moment'
import Card from '@/components/app/Card'
import LoadingState from '@/components/app/LoadingState'
// import VueHtml2pdf from 'vue-html2pdf'
import ListSoalCbt from '@/components/app/CbtGuru/ListSoalCbt'
import CounterCbt from '@/components/app/CbtGuru/CounterCbt'
import EditSoalCbt from '@/components/app/CbtGuru/EditSoalCbt'
import ExamCBT from '@/components/app/ExamCBT'
import KartuSoalPdf from '@/components/app/KartuSoalPdf'
import $ from 'jquery'
import JsPdf, { jsPDF } from 'jspdf'
import config from '@/config.js'

// import MuktaRegular from '@/fonts/Mukta-Regular-normal.js'
import AmiriRegular from '@/fonts/amiriregular-normal.js'
// const headerPdf = () => import('@/components/app/PdfComponents/Header')
// import HeaderPdf from '@/components/app/PdfComponents/Header'
// import PreviewSoalStudent from '@/components/app/CbtGuru/PreviewSoalStudent'
// import WirisPlugin from '@wiris/mathtype-generic'

const SoalPdf = () => import('@/components/app/SoalPdf')

export default {
  data() {
    return {
      isAttendanceSubmitted: false,
      isLoading: true,
      editable: false,
      loadingAdd: false,
      loadingEditQuestion: false,
      endOpen: false,
      visiblePop: false,
      loadingApprove: false,
      statusSlot: false,
      loadingExport: false,
      loadingUpdateQuestion: false,
      loadingDelete: false,
      newComponent: false,
      isPreviewStudent: false,
      isTeacher: true,
      ExportText: 'Export',
      detailCbt: {},
      dataSoal: [],
      newDataSoal: [],
      pilihans: ['A', 'B', 'C', 'D'],
      mapel: [],
      levels: [],
      pengawas: [],
      kdLists: [],
      classes: [],
      selectedPengawas: {
        pengawas1: '',
        pengawas2: '',
        pengawas3: '',
        pengawas4: '',
      },
      counterCBT: {
        counterQuestions: 0,
        counterEssay: 0,
        counterMultipleChoice: 0,
        counterEmpty: 0,
        counterBobot: 0,
      },
      pdfOptions: {
        margin: [10, 0, 0, 0],
        filename: '',
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
      },
      pdfOptions2: {
        margin: [0, 0, 0, 0],
        filename: '',
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
      },
      isExported: false,
      html2canvasScale: 0.55,
      html2canvasScale2: 0.55,
      schoolName: config.school.name2,
      isRenderMathJax: false,
    }
  },
  components: {
    Card,
    LoadingState,
    // VueHtml2pdf,
    CounterCbt,
    ListSoalCbt,
    EditSoalCbt,
    ExamCBT,
    KartuSoalPdf,
    SoalPdf,
    // PreviewSoalStudent,
  },
  methods: {
    duplicateCbt() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to duplicate this question(s) for new CBT?</div>
        ),
        onOk: () => {
          const id = this.$route.params.id
          this.$router.push({ name: 'Add Cbt Teacher', query: { duplicate: id } })
          // this.$notification.success({
          //   message: 'Success',
          //   description:
          //     'This Question has been removed',
          // })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Duplicate Now',
      })
    },
    handleDuration(value) {
      this.detailCbt.durasi = value
    },
    backDetail() {
      this.isPreviewStudent = false
    },
    cancelEdit() {
      this.newComponent = false
    },
    async saveEdit({ questions, deletedQuestions }) {
      try {
        const idCbt = this.$route.params.id
        this.newDataSoal = questions
        const cbtSoalBundle = this.newDataSoal.map(row => {
          if (this.isAttendanceSubmitted) {
            return {
              id: row.id,
              nomor_soal: 0,
              soal: row.soal,
              jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
              kriteria: row.kriteria,
              aspek: '',
              tipe_soal: row.tipe_soal,
              kunci_jawaban: row.kunci_jawaban,
              bobot_soal: row.bobot_soal,
              indikator: row.indikator,
              standar_kompetensi: row.standar_kompetensi,
            }
          } else {
            return {
              id: row.id,
              nomor_soal: 0,
              soal: row.soal,
              jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
              kriteria: row.kriteria,
              aspek: '',
              tipe_soal: row.tipe_soal,
              kunci_jawaban: row.kunci_jawaban,
              bobot_soal: row.bobot_soal,
              indikator: row.indikator,
              standar_kompetensi: row.standar_kompetensi,
            }
          }
        })
        const res = await this.$store.dispatch('cbt/PUT_CBT_SOAL', {
          idCbt,
          idGuru: this.user.id,
          cbtSoalBundle,
          deletedQuestions,
        })
        if (res === 'SUCCESS_EDIT_CBT_SOAL' || res === 'SUCCESS_EDIT_ANSWERED_CBT_SOAL') {
          this.newComponent = false
          this.resetCounter()
          this.fetchDataCbtDetail()
          this.$notification.success({
            message: 'Success.',
            description: 'Kartu Soal has been updated',
          })
        } else {
          this.$notification.error({
            message: 'Error.',
            description: 'Kartu Soal has not been updated',
          })
        }
      } catch (err) {
        console.log(err)
      }
      // this.resetCounter()
      // this.startCounter()
      // console.log(payload)
    },
    previewStudent() {
      this.isPreviewStudent = true
    },
    generateReport() {
      this.loadingExport = true
      this.ExportText = 'Exporting...'
      this.generateSoalPdf()
    },
    generateKartuSoal() {
      this.ExportText = 'Export Kartu Soal...'
      const doc = new JsPdf({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        compress: true,
        // putOnlyUsedFonts: true,
      })

      // doc.addFileToVFS('Mukta-Regular.ttf', MuktaRegular.font)
      // doc.addFont('Mukta-Regular.ttf', 'Mukta-Regular', 'normal')
      var callAddFont = function () {
        this.addFileToVFS('amiri-normal.ttf', AmiriRegular.font)
        this.addFont('amiri-normal.ttf', 'amiri', 'normal')
      }
      jsPDF.API.events.push(['addFonts', callAddFont])
      doc.addFileToVFS('amiri-normal.ttf', AmiriRegular.font)
      doc.addFont('amiri-normal.ttf', 'amiri', 'normal')
      doc.setFont('amiri', 'normal')

      const marginBottom = 20
      // const pageSize = doc.internal.pageSize
      // const pageHeight = pageSize.height

      const parent = $('#kartuSoalPdfPrint')
      // console.log(parent[0])

      const pTag = $('.kartuSoalPdf').find('p')
      pTag.each((i, el) => {
        el.replaceWith($('<div style="font-family: `amiri`!important" class="font-weight-normal"><span>' + el.innerHTML + '</span></div>')[0])
        $(el).css({ color: 'black', 'font-size': '18px' })
      })

      // console.log(pTag)

      // const imgTag = $('.kartuSoalPdf .butirSoal').find('img')
      // imgTag.each((i, el) => {
      //   // $(el).css('width', '150px!important')
      //   // el.width = 150
      //   $(el).attr('style', 'max-width: 150px!important')
      //   // console.log(el, el.width, el.style.width, 'ini img' + i)
      // })
      // console.log(imgTag, 'ini img')

      const childrens = $('.kartuSoalPdf')
      childrens.each((i, el) => {
        parent.append(el)
      })

      setTimeout(() => {
        doc.html(parent[0], {
          callback: (doc) => {
            const yFooter = doc.internal.pageSize.getHeight() - marginBottom
            const totalPages = doc.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i++) {
              doc.setPage(i)
              doc.setFontSize(10)
              doc.setTextColor(150)
              doc.text(this.schoolName, (doc.internal.pageSize.getWidth() * 0.50), (yFooter))
              doc.text('Page ' + i + ' of ' + totalPages, (doc.internal.pageSize.getWidth() * 0.88), (yFooter))
            }
            // console.log('oye')
            doc.save(this.pdfOptions2.filename + '.pdf')
            this.loadingExport = false
            this.ExportText = 'Export'
            // this.isExported = false
            // this.loadingExport = false
          },
          html2canvas: {
            // height: 500,
            letterRendering: true,
            useCORS: true,
            scale: this.html2canvasScale2,
          },
          pageSplit: true,
          // x: 100,
          // y: 150,
        })
        // return console.log(data, JsPdf)
        // }, 400)
      }, 500)
    },
    generateSoalPdf() {
      this.ExportText = 'Export Soal...'
      this.loadingExport = true
      // const data = $('#soalPdf .content')
      // console.log(data)
      // const doc = new JsPdf({
      //   // orientation: 'p',
      //   unit: 'px',
      //   // format: 'a4',
      //   // putOnlyUsedFonts: true,
      // })
      // data.each(async (i, el) => {
      //   await doc.html(el, {
      //     html2canvas: {
      //       // height: 500,
      //       letterRendering: true,
      //       useCORS: true,
      //       // scale: 0.60,
      //     },
      //     x: 10,
      //     y: 10,
      //     margin: [30, 10, 0, 10],
      //   })
      //   console.log(i, data.length)
      // })
      // doc.save('data.pdf')
      // console.log(doc.internal.pageSize)
      // this.$refs.html2Pdf.generatePdf()
      // this.$refs.html2Pdf2.generatePdf()
      // this.loadingExport = true
      // for (let idx = 0; idx < this.dataGeneralReports.length; idx++) {
      //   await this.$refs['report' + idx][0].generatePdf()
      // }
      // this.isExported = true
      const doc = new JsPdf({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        compress: true,
        // putOnlyUsedFonts: true,
      })
      var callAddFont = function () {
        this.addFileToVFS('amiri-normal.ttf', AmiriRegular.font)
        this.addFont('amiri-normal.ttf', 'amiri', 'normal')
      }
      jsPDF.API.events.push(['addFonts', callAddFont])
      doc.addFileToVFS('amiri-normal.ttf', AmiriRegular.font)
      doc.addFont('amiri-normal.ttf', 'amiri', 'normal')
      doc.setFont('amiri', 'normal')
      // console.log(doc.getFontList())
      // doc.addFileToVFS('Mukta-Regular-normal.ttf', MuktaRegular.font)
      // doc.addFont('Mukta-Regular-normal.ttf')
      // doc.setFont('Mukta-Regular-normal.ttf')

      const marginBottom = 10
      const marginTop = 20

      // setTimeout(() => {
      // const data = document.getElementById('soalPdf')
      // const data = $('#soalPdf .content')
      // data.style = {
      //   width: doc.internal.pageSize.getWidth(),
      // }
      // doc.add
      const parent = $('#cobaSoalPdf')
      // doc.setFont('Montserrat')
      let totalPage = 1
      parent.append($(`<div id="page-${totalPage}" />`)[0])
      let currentPageEl = $(`#page-${totalPage}`)[0]
      const header = document.getElementById('headerPdf')
      const coc = document.getElementById('codeOfConduct')
      currentPageEl.append(header)
      const cocPTag = $('#codeOfConduct p')
      cocPTag.each((i, el) => {
        el.replaceWith($('<div style="font-family: `amiri`!important" class="font-weight-normal"><span>' + el.innerHTML + '</span></div>')[0])
        $(el).css({ 'font-size': '16px', color: 'black' })
      })
      // console.log(coc)
      currentPageEl.append(coc)
      const contents = $('#soalPdf .content')
      const pageSize = doc.internal.pageSize
      const pageHeight = pageSize.height
      contents.each((i, el) => {
        const childrenPOnEl = $(el).find('p')
        // console.log(childrenPOnEl, 'hehehe')
        childrenPOnEl.each((i, elChild) => {
          elChild.replaceWith($('<div style="font-family: `amiri`!important" class="font-weight-normal"><span>' + elChild.innerHTML + '</span></div>')[0])
          $(elChild).css({ 'font-size': '16px', color: 'black' })
        })
        const childrenSpanOnEl = $(el).find('span')
        childrenSpanOnEl.each((i, elSpan) => {
          $(elSpan).css({ 'font-size': '16px', color: 'black' })
        })
        const marginEl = parseInt(el.style['margin-bottom'])
        const elHeight = ($(el).height() + marginEl) * this.html2canvasScale
        // let isFit = false
        // while (!isFit) {
        // console.log(i)
        // const currentPageHeight = ($(currentPageEl).height() + marginEl + marginBottom) * this.html2canvasScale
        const currentPageHeight = ($(currentPageEl).height()) * this.html2canvasScale
        if (currentPageHeight + elHeight + (marginBottom * 2) + 10 > pageHeight) {
          const remainingSpace = (pageHeight - currentPageHeight - marginBottom) / this.html2canvasScale
          // const additionalDiv = `<div style="height: ${remainingSpace}px" class='d-flex align-items-end'>${currentPageHeight}, ${elHeight}</div>`
          const additionalDiv = `<div style="height: ${remainingSpace}px"></div>`
          $(currentPageEl).append($(additionalDiv)[0])
          // console.log(currentPageHeight, 'ini ' + i, currentPageEl)
          totalPage++
          const newPageEl = $(`<div id="page-${totalPage}"><div style="height: ${marginTop}px" /></div>`)
          parent.append(newPageEl[0])
          currentPageEl = $(`#page-${totalPage}`)[0]
        } else {
          // if (currentPageHeight + elHeight > pageHeight) {
          // $(currentPageEl).append($('<br />')[0])
          // }
          // isFit = true
        }
        // console.log($(currentPageEl).height(), marginEl, marginBottom, this.html2canvasScale, currentPageHeight, i, totalPage, el, pageHeight, currentPageHeight + elHeight, elHeight)
        $(currentPageEl).append(el)
        // }
        // console.log(totalHeight)
        // console.log(pageSize, el, elHeight, 'oye')
      })

      setTimeout(() => {
        doc.html(parent[0], {
          callback: (doc) => {
            const yFooter = doc.internal.pageSize.getHeight() - marginBottom
            const totalPages = doc.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i++) {
              doc.setPage(i)
              doc.setFontSize(10)
              doc.setTextColor(150)
              doc.text(this.schoolName, (doc.internal.pageSize.getWidth() * 0.50), (yFooter))
              doc.text('Page ' + i + ' of ' + totalPages, (doc.internal.pageSize.getWidth() * 0.88), (yFooter))
            }
            doc.save(this.pdfOptions.filename + '.pdf')
            this.generateKartuSoal()
            // this.isExported = false
            // this.loadingExport = false
          },
          html2canvas: {
            // height: 500,
            letterRendering: true,
            useCORS: true,
            scale: this.html2canvasScale,
          },
          pageSplit: true,
          // x: 100,
          // y: 150,
        })
        // return console.log(data, JsPdf)
        // }, 400)
      }, 500)
    },
    generateReport2() {
      this.$refs.html2Pdf2.generatePdf()
    },
    onProgress(event) {
      this.loadingExport = true
      this.ExportText = 'Exporting...'
      // console.log('Progress  ', event)
    },
    hasStartedGeneration() {
      console.log('Start Generate')
    },
    hasGenerated(event) {
      console.log('Generated', event)
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text(this.schoolName, (pdf.internal.pageSize.getWidth() * 0.50), (pdf.internal.pageSize.getHeight() - 2))
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 2))
        }
      }).save()
      this.loadingExport = false
      this.ExportText = 'Export Kartu Soal to PDF'
    },
    numberQuestion(index) {
      return ++index
    },
    handleBobot(value, i) {
      this.newDataSoal[i].bobot_soal = value
      this.counterCBT.counterBobot = 0
      this.newDataSoal.forEach(row => {
        this.counterCBT.counterBobot += parseFloat(row.bobot_soal)
      })
    },
    handleCriteria(value, i) {
      this.newDataSoal[i].kriteria = value
    },
    handleCorrectAnswer(value, i) {
      this.newDataSoal[i].kunci_jawaban = value
    },
    removeQuestion(index, soal) {
      // console.log(index)
      if (this.isAttendanceSubmitted) {
        this.$notification.error({
          message: 'Error',
          description:
            'You cannot delete this question, when students has already answered.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to remove this question ?</div>
          ),
          onOk: () => {
            this.counterCBT.counterQuestions -= 1
            this.counterCBT.counterBobot -= soal.bobot_soal
            if (soal.tipe_soal === 'Multiple Choice') {
              this.counterCBT.counterMultipleChoice -= 1
            } else {
              this.counterCBT.counterEssay -= 1
            }
            this.newDataSoal.splice(index, 1)
            this.$notification.success({
              message: 'Success',
              description:
                'This Question has been removed',
            })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'danger',
          okText: 'Remove',
        })
      }
    },
    addQuestion(type) {
      const idCbt = this.$route.params.id
      const data = {}
      this.visiblePop = false
      if (this.isAttendanceSubmitted) {
        this.$notification.error({
          message: 'Error',
          description:
            'You cannot add the question, when students has already answered.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to add {type} question ?</div>
          ),
          onOk: () => {
            if (type === 'Multiple Choice') {
              data.jawaban = ['a. type your choice here', 'b. type your choice here', 'c. type your choice here', 'd. type your choice here']
              data.kunci_jawaban = 'a'
              this.counterCBT.counterMultipleChoice += 1
            } else {
              data.jawaban = ''
              this.counterCBT.counterEssay += 1
            }
            data.id_cbt = idCbt
            data.tipe_soal = type
            data.soal = 'Type your question here...'
            data.bobot_soal = 1
            data.kriteria = 'SD'
            this.counterCBT.counterQuestions += 1
            this.counterCBT.counterBobot += data.bobot_soal
            this.newDataSoal.push(data)
            this.$notification.success({
              message: 'Success',
              description:
                'This Question has been added',
            })
          },
          onCancel: () => {
            this.visiblePop = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Add',
        })
      }
    },
    updateQuestion() {
      if (this.counterCBT.counterBobot !== 100) {
        this.$notification.error({
          message: 'Sorry.',
          description: `Total Bobot Soal is ${this.counterCBT.counterBobot}, Make sure total Bobot Soal is 100.`,
        })
      } else {
        this.loadingUpdateQuestion = true
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to update the questions ?</div>
          ),
          onOk: () => {
            const idCbt = this.$route.params.id
            const cbtSoalBundle = this.newDataSoal.map(row => {
              if (this.isAttendanceSubmitted) {
                return {
                  id: row.id,
                  nomor_soal: 0,
                  soal: row.soal,
                  jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
                  kriteria: row.kriteria,
                  aspek: '',
                  tipe_soal: row.tipe_soal,
                  kunci_jawaban: row.kunci_jawaban,
                  bobot_soal: row.bobot_soal,
                  indikator: row.indikator,
                  standar_kompetensi: row.standar_kompetensi,
                }
              } else {
                return {
                  nomor_soal: 0,
                  soal: row.soal,
                  jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
                  kriteria: row.kriteria,
                  aspek: '',
                  tipe_soal: row.tipe_soal,
                  kunci_jawaban: row.kunci_jawaban,
                  bobot_soal: row.bobot_soal,
                  indikator: row.indikator,
                  standar_kompetensi: row.standar_kompetensi,
                }
              }
            })
            this.$store.dispatch('cbt/PUT_CBT_SOAL', {
              idCbt,
              idGuru: this.user.id,
              cbtSoalBundle,
            })
              .then(res => {
                this.loadingUpdateQuestion = false
                this.editable = false
                if (res === 'SUCCESS_EDIT_CBT_SOAL') {
                  this.resetCounter()
                  this.fetchDataCbtDetail()
                  this.$notification.success({
                    message: 'Success.',
                    description: 'Kartu Soal has been updated',
                  })
                } else {
                  this.$notification.error({
                    message: 'Error.',
                    description: 'Kartu Soal has not been updated',
                  })
                }
              })
          },
          onCancel: () => {
            this.loadingUpdateQuestion = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Update',
        })
      }
    },
    handleSelectMapel(value) {
      this.detailCbt.id_mata_pelajaran = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSelectPengawas(value, id, column) {
      // this.detailCbt.id_pengawas = value
      // this.selectedPengawas[`pengawas${no}`] = value

      const target = this.detailCbt.list_pengawas.find(kelas => kelas.id === id)
      if (target) {
        target[column] = value
      }
      // console.log(this.detailCbt.list_pengawas)
    },
    handleSelectClass(value) {
      this.detailCbt.tingkat = value
    },
    handleSelectType(value) {
      this.detailCbt.tipe = value
    },
    disabledStartDate(startValue) {
      const endValue = this.detailCbt.waktu_ujian_ditutup
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.detailCbt.waktu_ujian_dibuka
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    resetCounter() {
      this.counterCBT.counterQuestions = 0
      this.counterCBT.counterEssay = 0
      this.counterCBT.counterMultipleChoice = 0
      this.counterCBT.counterBobot = 0
      this.counterCBT.counterEmpty = 0
    },
    async fetchDataMapel() {
      try {
        const data = await this.$store.dispatch('cbt/FETCH_LIST_KELAS_GURU_PENGAMPU')
        this.mapels = data
        // console.log(this.detailCbt.id_mata_pelajaran)
        const target = this.mapels.find(el => el.id === this.detailCbt.id_mata_pelajaran)
        // console.log(target)
        if (target) {
          this.classes = target.kelas
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchDataKd() {
      try {
        const data = await this.$store.dispatch('cbt/FETCH_KD_BY_ID_MAPEL', { idMapel: this.detailCbt.id_mata_pelajaran })
        // console.log(data)
        this.kdLists = data
      } catch (err) {
        console.log(err)
      }
    },
    fetchDataPengawas() {
      this.$store.dispatch('cbt/FETCH_PENGAWAS')
        .then(data => {
          this.pengawas = data
        })
    },
    fetchDataCbt() {
      const idCbt = this.$route.params.id
      const idGuru = this.user.id
      this.isLoading = true
      this.$store.dispatch('cbt/FETCH_CBT_BY_ID', { idCbt, idGuru })
        .then(res => {
          this.isLoading = false
          const kelasTerpilih = res.listPengawas.map(kelas => {
            return kelas.kelas
          }) || []
          this.detailCbt = { ...res.cbt, list_pengawas: res.listPengawas, kelasTerpilih }
          this.detailCbt.waktu_ujian_dibuka = moment(this.detailCbt.waktu_ujian_dibuka).format('DD MMM YYYY HH:mm:ss')
          this.detailCbt.waktu_ujian_ditutup = moment(this.detailCbt.waktu_ujian_ditutup).format('DD MMM YYYY HH:mm:ss')
          // this.dataCBT.instruksi = this.detailCbt.instruksi
          // console.log(res.isAttendanceSubmitted)
          this.isAttendanceSubmitted = res.isAttendanceSubmitted
          // if (res.isAttendanceSubmitted) {
          //   this.isAttendanceSubmitted = true
          // } else {
          //   this.isAttendanceSubmitted = false
          // }
          const tipe = res.cbt.tipe
          // console.log(tipe)
          let detailTipe = ''
          if (tipe === 'PH') {
            detailTipe = 'PENILAIAN HARIAN'
          } else if (tipe === 'PTS') {
            detailTipe = 'PENILAIAN TENGAH SEMESTER'
          } else if (tipe === 'PAS') {
            detailTipe = 'PENILAIAN AKHIR SEMESTER'
          } else if (tipe === 'PAT') {
            detailTipe = 'PENILAIAN AKHIR TAHUN'
          }
          // console.log(res.listPengawas)
          // res.listPengawas.forEach(el => {})
          if (res.listPengawas) {
            res.listPengawas.forEach((el, index) => {
              this.selectedPengawas[`pengawas${++index}`] = el.id
            })
          }
          this.fetchDataKd()
          // console.log(this.selectedPengawas)
          this.detailCbt.tipePanjang = detailTipe
          this.pdfOptions.filename = `SOAL ${this.detailCbt.tipe}-${this.detailCbt.mata_pelajaran.nama}.pdf`
          this.pdfOptions2.filename = `KARTU SOAL ${this.detailCbt.tipe}-${this.detailCbt.mata_pelajaran.nama}.pdf`
        })
    },
    fecthIsSlot() {
      const idGuru = this.user.id
      const idCbt = this.$route.params.id
      this.$store.dispatch('cbt/FETCH_CBT_SLOT', { idCbt, idGuru })
        .then(res => {
          if (res === 'TRUE_GURU_MAPEL') {
            this.statusSlot = true
          } else {
            this.statusSlot = false
          }
        })
    },
    fetchDataCbtDetail() {
      const idCbt = this.$route.params.id
      this.isRenderMathJax = true
      this.$store.dispatch('cbt/FETCH_CBT_ATTENDANCES_BY_ID', { idCbt, page: '', idKelas: '' })
        .then(res => {
          this.dataSoal = res.cbt.cbt_soals.map(row => {
            // const soal = WirisPlugin.Parser.initParse(row.soal)
            return {
              id: row.id,
              id_cbt: row.id_cbt,
              tipe_soal: row.tipe_soal,
              // soal,
              soal: row.soal,
              jawaban: row.tipe_soal === 'Multiple Choice' ? JSON.parse(row.jawaban) : row.jawaban,
              kunci_jawaban: row.kunci_jawaban,
              bobot_soal: row.bobot_soal,
              kriteria: row.kriteria,
              indikator: row.indikator,
              standar_kompetensi: row.standar_kompetensi,
            }
          })
          this.counterCBT.counterQuestions = this.dataSoal.length
          this.dataSoal.forEach(row => {
            this.counterCBT.counterBobot += parseFloat(row.bobot_soal)
            if (row.soal && (row.jawaban === '""' || row.jawaban === null)) {
              this.counterCBT.counterEssay++
            } else if (row.soal && row.jawaban && row.kunci_jawaban) {
              this.counterCBT.counterMultipleChoice++
            // } else {
            //   this.counterCBT.counterEmpty++
            }
          })
          this.isRenderMathJax = false
        })
    },
    cekEmpty() {
      if (!this.editable) {
        if (this.counterCBT.counterEmpty > 0) {
          this.editable = false
          this.$notification.error({
            message: 'Sorry.',
            description: `There are ${this.counterCBT.counterEmpty} questions that are still empty, Please recheck the Kartu Soal document`,
          })
        } else {
          this.loadingEditQuestion = true
          const delay = t => new Promise(resolve => setTimeout(resolve, t))
          delay(100).then(() => {
            // this.editable = true
            this.newComponent = true
            this.loadingEditQuestion = false
            this.newDataSoal = this.dataSoal
          })
        }
      } else if (this.editable) {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to cancel editing ? your changes will be lost</div>
          ),
          onOk: () => {
            this.editable = false
            this.resetCounter()
            this.fetchDataCbtDetail()
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes',
        })
      }
    },
    generateCBT() {
      if (this.counterCBT.counterEmpty > 0) {
        this.$notification.error({
          message: 'Sorry.',
          description: `There are ${this.counterCBT.counterEmpty} questions that are still empty, Please recheck the Kartu Soal document`,
        })
      } else if (this.counterCBT.counterBobot !== 100) {
        this.$notification.error({
          message: 'Sorry.',
          description: `Total Bobot Soal is ${this.counterCBT.counterBobot}, Make sure total Bobot Soal is 100.`,
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure want to save this CBT ?</div>
          ),
          onOk: () => {
            this.loadingAdd = true
            if (this.counterCBT.counterMultipleChoice !== 0 && this.counterCBT.counterEssay !== 0) {
              this.detailCbt.tipe_cbt = 'Multiple Choice & Essay'
            } else if (this.counterCBT.counterMultipleChoice !== 0 && this.counterCBT.counterEssay === 0) {
              this.detailCbt.tipe_cbt = 'Multiple Choice'
            } else if (this.counterCBT.counterMultipleChoice === 0 && this.counterCBT.counterEssay !== 0) {
              this.detailCbt.tipe_cbt = 'Essay'
            }
            this.detailCbt.waktu_ujian_dibuka = moment(this.detailCbt.waktu_ujian_dibuka).format('YYYY-MM-DD HH:mm:ss')
            this.detailCbt.waktu_ujian_ditutup = moment(this.detailCbt.waktu_ujian_ditutup).format('YYYY-MM-DD HH:mm:ss')
            const idCbt = this.$route.params.id
            this.detailCbt.id_guru = this.user.id
            const dataCBT = this.detailCbt
            const pengawas = this.detailCbt.list_pengawas
            // console.log(dataCBT, pengawas)
            this.$store.dispatch('cbt/PUT_CBT', {
              idCbt,
              idGuru: this.user.id,
              dataCBT,
              pengawas,
            })
              .then(res => {
                this.dataCBT = {}
                this.excelData = []
                this.$notification.success({
                  message: 'Success.',
                  description: 'CBT has been saved',
                })
                this.$router.push({ name: 'Cbt Teacher' })
                this.$store.commit('menu/SET_STATE', {
                  currentMenu: ['Cbt Teacher'],
                })
              })
              .catch(err => {
                console.log(err)
                this.$notification.error({
                  message: 'Error.',
                  description: 'CBT has not been saved. Please try again later',
                })
              })
              .finally(() => {
                this.loadingAdd = false
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Save',
        })
      }
      // console.log(this.dataCBT)
    },
    approveCBT() {
      const idCbt = this.$route.params.id
      const idGuru = this.user.id
      this.loadingApprove = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to approve this CBT ?</div>
        ),
        onOk: () => {
          this.$store.dispatch('cbt/APPROVE_CBT', {
            idCbt,
            idGuru,
          })
            .then(res => {
              this.$notification.success({
                message: 'Success.',
                description: 'CBT has been approved',
              })
              this.$router.push({ name: 'Cbt Teacher' })
              this.$store.commit('menu/SET_STATE', {
                currentMenu: ['Cbt Teacher'],
              })
            })
            .catch(err => {
              console.log(err)
              this.$notification.error({
                message: 'Error.',
                description: 'CBT cannot be approved. Please try again later',
              })
            })
            .finally(() => {
              this.loadingApprove = false
            })
        },
        onCancel: () => {
          this.loadingApprove = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Approve',
      })
    },
    deleteCBT() {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to remove this question ? your data will be lost</div>
        ),
        onOk: () => {
          const idCbt = this.$route.params.id
          this.$store.dispatch('cbt/DELETE_CBT', {
            idCbt,
          })
            .then(res => {
              this.$notification.success({
                message: 'Success',
                description:
                  'CBT has been removed',
              })
              this.$router.push({ name: 'Cbt Teacher' })
              this.$store.commit('menu/SET_STATE', {
                currentMenu: ['Cbt Teacher'],
              })
            })
            .catch(err => {
              console.log(err)
              this.$notification.error({
                message: 'Error.',
                description: 'CBT cannot be deleted. Please try again later',
              })
            })
            .finally(() => {
              this.loadingDelete = false
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
  },
  computed: {
    user() { return this.$store.state.user.user },
    role() {
      return this.$store.state.user.role
    },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      return this.$store.state.ckEditor.editorConfig
    },
    editorConfigInstruksi() {
      return {
        ...this.$store.state.ckEditor.editorConfig,
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'fontColor',
            '|',
            'bulletedList',
            'numberedList',
            'undo',
            'redo',
          ],
        },
      }
    },
    findDuplicatesPengawas() {
      if (this.detailCbt.tipe !== 'PH') {
        const arr = Object.values(this.selectedPengawas)
        const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
        const duplicates = findDuplicates(arr)
        // console.log(duplicates.length)
        if (duplicates.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isFillCBT() {
      if (this.detailCbt.durasi && this.detailCbt.waktu_ujian_dibuka && this.detailCbt.waktu_ujian_ditutup && this.detailCbt.instruksi) {
        return true
      } else {
        return false
      }
    },
    isFillAll() {
      if (this.dataSoal.length && this.isFillCBT) {
        return false
      } else {
        return true
      }
    },
    fileNameKartuSoal() {
      return `Kartu Soal ${this.detailCbt.tipe}-${this.detailCbt.mata_pelajaran?.nama}-${this.detailCbt.tingkat}.pdf`
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    kelas() {
      let data = null
      if (['PTS', 'PAS'].includes(this.detailCbt.tipe)) {
        data = this.detailCbt.level?.nama
      } else {
        data = this.detailCbt.list_pengawas?.map(cbt => cbt.kelas).join(' / ')
      }

      return data
    },
    tanggalUjian() {
      let data = null
      if (['PTS', 'PAS'].includes(this.detailCbt.tipe)) {
        const waktuDibuka = this.detailCbt.waktu_ujian_dibuka
        // data = waktuDibuka ? `${moment(waktuDibuka, 'YYYY-MM-DD HH:mm').add(7, 'hours').locale('id').format('dddd, DD MMMM YYYY HH:mm')}` : ''
        data = waktuDibuka ? `${moment(waktuDibuka, 'YYYY-MM-DD').locale('id').format('dddd, DD MMMM YYYY')}` : ''
        // const waktuDitutup = this.detailCbt.waktu_ujian_ditutup
        // data = waktuDitutup ? data + ` - ${moment(waktuDitutup, 'YYYY-MM-DD HH:mm').locale('id').format('dddd, DD MMMM YYYY HH:mm')}` : data
      } else {
        let listPengawas = this.detailCbt?.list_pengawas ? [...this.detailCbt.list_pengawas] : []
        listPengawas = listPengawas.sort((a, b) => a.waktu_ujian_dibuka - b.waktu_ujian_dibuka)
        if (listPengawas.length) {
          const waktuDibuka = listPengawas[0].waktu_ujian_dibuka
          // data = waktuDibuka ? `${moment(waktuDibuka, 'YYYY-MM-DD HH:mm').add(7, 'hours').locale('id').format('dddd, DD MMMM YYYY HH:mm')}` : ''
          data = waktuDibuka ? `${moment(waktuDibuka, 'YYYY-MM-DD').locale('id').format('dddd, DD MMMM YYYY')}` : ''
          // const waktuDitutup = listPengawas[listPengawas.length - 1].waktu_ujian_ditutup
          // data = waktuDitutup ? data + ` - ${moment(waktuDibuka, 'YYYY-MM-DD HH:mm').locale('id').format('dddd, DD MMMM YYYY HH:mm')}` : data
        }
      }

      return data
    },
  },
  created() {
    this.fetchDataMapel()
    this.fetchDataPengawas()
    this.fetchDataCbt()
    this.fecthIsSlot()
  },
  mounted() {
    this.fetchDataCbtDetail()
  },
  watch: {
    newComponent(val) { console.log(this.$refs) },
  },
}
</script>

<style lang="scss">
.cbtDetail {
  .card-full {
    padding: 20px 20px;
    margin-top: 60px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0419301a;
  }

  .detail {
    padding: 15px;
  }

}
#cobaSoalPdf, .kartuSoalPdf, #soalPdf, #kartuSoalPdfPrint {
  figure {
    max-width: 150px;
  }
}
</style>
