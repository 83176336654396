<template>
  <div>
    <hr />
    <div v-for="(item, i) in listSoal" :key="i">
      <a-row :gutter="0">
        <a-col :span="2">
          <h3>{{ numberQuestion(i) }}.</h3>
        </a-col>
        <a-col :span="22">
          <span class="soal" ref="soal" v-html="item.soal ? item.soal : 'Empty'"></span>
          <div class="mt-3 d-flex" v-for="(itemJawaban, j) in item.jawaban" :key="j">
            <div>{{String.fromCharCode(65 + j).toLowerCase()}}.&nbsp;</div>
            <div>
              <span class="soal" ref="jawaban" v-html="itemJawaban"></span>
            </div>
          </div>
          <div class="mt-3 font-weight-bold">
            <span v-if="item.tipe_soal === 'Multiple Choice'" class="mr-4"
              >Kunci Jawaban : {{ item.kunci_jawaban }}</span
            >
            <span class="mr-4">Bobot Soal : {{ item.bobot_soal }}</span>
            <span class="mr-4">Kriteria : {{ item.kriteria }}</span>
          </div>
        </a-col>
      </a-row>
      <hr />
    </div>
  </div>
</template>

<script>
import { renderMathMl } from '@/helpers/mathjax'

export default {
  name: 'ListSoalCbt',
  props: {
    listSoal: {
      type: Array,
      required: true,
    },
  },
  methods: {
    numberQuestion(index) {
      return ++index
    },
    renderMath() {
      // console.log('masuk')
      let arrayOfRef = []
      const soal = this.$refs.soal
      const jawaban = this.$refs.jawaban
      if (soal || jawaban) {
        if (soal) {
          if (Array.isArray(soal)) arrayOfRef = arrayOfRef.concat(soal)
          else arrayOfRef.push(soal)
        }

        if (jawaban) {
          if (Array.isArray(jawaban)) arrayOfRef = arrayOfRef.concat(jawaban)
          else arrayOfRef.push(jawaban)
        }

        renderMathMl(arrayOfRef)
        // console.log('1')
      }
    },
  },
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
    multipleChoiceAmount() {
      return this.institusi?.jumlah_pilihan_ganda || 4
    },
  },
  mounted() {
    this.renderMath()
  },
}
</script>

<style>
</style>
